import React from "react";

export const TechTable = ({ items }) => {
  return (
    <table className="text-left w-full">
      <tbody>
        {items.map((item, i) => {
          return (
            <tr key={"t-" + i} className="border-b">
              <td className="py-2 text-gray-700">{item.key}</td>
              <td className="py-2 flex flex justify-end text-right">
                {item.value}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
export default TechTable;
