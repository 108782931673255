import React from "react";

export const FeatureCard = ({ title, items }) => {
  return (
    <div className="rounded shadow-md px-4 py-6 h-full bg-white text-center">
      <p className="text-xl font-semibold mb-4">{title}</p>
      {items.map((item, index) => {
        if (item.hasOwnProperty("link")) {
          return (
            <a
              key={"c-" + index}
              href={item.link}
              className="mt-2 block text-sm text-blue-700 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.name}
            </a>
          );
        }
        return (
          <p key={"c-" + index} className="mt-2 text-sm">
            {item}
          </p>
        );
      })}
    </div>
  );
};

export default FeatureCard;
