import React from "react";
import NonwovenImage from "../assets/image/Nonwoven.jpg";
import TechTable from "../components/TechTable";
import FeatureCard from "../components/FeatureCard";
import { ReactComponent as Check } from "feather-icons/dist/icons/check.svg";

const DETAILS = [
  {
    key: "Product width",
    value: "up to 15000 mm"
  },
  {
    key: "Head scanning speed",
    value: "100 to 400 mm"
  },
  {
    key: "Max sensors in platform",
    value: "3-5"
  },
  {
    key: "Liquid cooling of sensor platform",
    value: "Only when required"
  },
  {
    key: "Over pressurized scanner frame with fresh air intakes 200 mm",
    value: <Check className="icon h-6" />
  },
  {
    key: "Head tilt possibility",
    value: "+/- 35 degrees"
  },
  {
    key: "Field bus in sensor platform",
    value: <Check className="icon h-6" />
  },
  {
    key: "Built in PLC in scanner frame",
    value: <Check className="icon h-6" />
  },
  {
    key: "Very simple integration into line",
    value: <Check className="icon h-6" />
  },
  {
    key: "Direct Profinet/ Ethernet connection to line control PLC",
    value: <Check className="icon h-6" />
  },
  {
    key: "Special scanners for Pre-tacker, Needling and Finishing lines",
    value: <Check className="icon h-6" />
  }
];

const Nonwoven = () => {
  return (
    <>
      <header
        className="relative bg-blue-900 overflow-hidden"
        style={{
          height: "40rem",
          backgroundImage: `url(${NonwovenImage})`,
          backgroundSize: "cover",
          bacgroundRepeat: "no-repeat",
          backgroundPosition: "top center"
        }}
      >
        <div
          className="absolute inset-0 flex items-center justify-center text-white px-8"
          style={{
            background: "rgba(14,57,103,0.7)"
          }}
        >
          <div className="text-center sm:w-2/3">
            <p className="title text-2xl">Measuring systems for</p>
            <p className="title text-5xl">Nonwoven products</p>
          </div>
        </div>
      </header>

      <section className="px-8 py-24">
        <div className="sm:w-2/3 mx-auto text-center">
          <p className="title text-3xl">Overview</p>
          <p className="text-lg mt-8">
            Scienta has specialized in measuring felt and other synthetic
            nonwoven materials. We have developed special non-radioactive
            sensors to measure Basis Weight of PP and PES. Our single sided
            Microwave sensor is especially suited for paper machine Felt
            measurements.
          </p>
        </div>
      </section>
      <section className="px-8 py-24 border-t bg-gray-200">
        <div className="text-center">
          <p className="title text-3xl mb-4">Features</p>
          <div className="sm:flex sm:flex-wrap sm:-mx-4">
            <div className="sm:w-1/4 sm:p-4 mt-12 sm:mt-0">
              <FeatureCard
                title="Online sensors"
                items={[
                  {
                    name: "Total weight",
                    link: "/sensors/Scienta_BW_Basis_Weight.pdf"
                  },
                  {
                    name: "Moisture",
                    link: "/sensors/Scienta_NIR_Moisture.pdf"
                  },
                  {
                    name: "Porosity",
                    link: "/sensors/Scienta_Porosity.pdf"
                  },
                  "Sheet temperature"
                ]}
              />
            </div>
            <div className="sm:w-1/4 sm:p-4 mt-12 sm:mt-0">
              <FeatureCard
                title="Scanner frames"
                items={[
                  {
                    name: "Medium size double sided scanner",
                    link: "/scanners/Scienta_9110_Scanner.pdf"
                  },
                  {
                    name: "Single sided scanner",
                    link: "/scanners/Scienta_9145_Scanner.pdf"
                  },
                  {
                    name: "Fixed point scanner",
                    link: "/scanners/Scienta_9144_Scanner.pdf"
                  },
                  "Mobile units"
                ]}
              />
            </div>
            <div className="sm:w-1/4 sm:p-4 mt-12 sm:mt-0">
              <FeatureCard
                title="User interface/IO"
                items={[
                  "Siemens WinCC Flexible",
                  "Allen-Bradley RSView Studio",
                  "OPC Server",
                  "SQL Database",
                  "Excel format data gathering"
                ]}
              />
            </div>
            <div className="sm:w-1/4 sm:p-4 mt-12 sm:mt-0">
              <FeatureCard
                title="Automatic controls"
                items={["Basis Weight MD"]}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="px-8 py-24 border-t">
        <div className="text-center">
          <p className="title text-3xl mb-4">Technical details</p>
          <div className="sm:w-2/3 mx-auto">
            <TechTable items={DETAILS} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Nonwoven;
