import React from "react";
import { Person } from "./About";

const Contact = ({ data }) => {
  const { sales, representatives } = data;
  return (
    <>
      <header className="relative bg-blue-900" style={{ height: "40rem" }}>
        <div className="absolute inset-0 flex items-center justify-center text-white px-8">
          <div className="text-center sm:w-2/3">
            <p className="title text-5xl">Contact</p>
            <p className="text-xl mt-4">Sales & Representation and Service</p>
          </div>
        </div>
      </header>
      <section className="px-8 py-24 border-t">
        <div className="sm:w-2/3 mx-auto text-center">
          <p className="title text-3xl">Sales and Representation</p>
          <p className="text-lg mt-4 mb-4">
            Please contact us for further information.
          </p>
          <div className="sm:flex sm:flex-wrap sm:-mx-4">
            {sales.map((person, i) => {
              return (
                <div key={i} className="sm:w-1/2 p-4">
                  <Person person={person} />
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="px-8 py-24 border-t">
        <div className="sm:w-2/3 mx-auto text-center">
          <p className="title text-3xl">Service</p>
          <p className="text-lg mt-4 mb-4">
            Scienta has a worldwide service network. Find our closest point of
            contact below.
          </p>
          <div className="sm:flex sm:flex-wrap sm:-mx-4">
            {representatives.map((person, i) => {
              return (
                <div key={i} className="sm:w-1/2 p-4">
                  <Person person={person} />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
