import React from "react";
import Scanning from "../assets/video/scanning.mp4";
import { Link } from "@reach/router";

const Home = () => {
  return (
    <>
      <header
        className="relative bg-blue-900 overflow-hidden"
        style={{ height: "40rem" }}
      >
        <video
          className="absolute inset-0 opacity-0 sm:opacity-100"
          autoPlay
          loop
          muted
        >
          <source src={Scanning} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div
          className="absolute inset-0 flex items-center justify-center text-white px-8"
          style={{ background: "rgba(14,57,103,0.7)" }}
        >
          <div className="text-center sm:w-2/3">
            <p className="title text-5xl">Innovative measuring technology</p>
            <p className="text-xl mt-4 text-gray-200">
              Customized measuring solutions for the Pulp & Paper, Impregnated
              paper and Nonwoven industries.
            </p>
            <a
              href="#whatwedo"
              className="button border-white mt-4 inline-block"
            >
              Read more
            </a>
          </div>
        </div>
      </header>
      <section className="px-8 py-24 text-center" id="whatwedo">
        <div className="sm:w-2/3 mx-auto">
          <p className="title text-3xl">What we do</p>
          <p className="text-lg mt-8">
            Our passion is measuring technology and we have been building
            reliable, high performing sensors for decades. The development
            principles are robustness, extensive use of Field bus technology and
            simplicity in design. We often can integrate the whole sensor
            electronics into a single card.
          </p>
          <p className="text-lg mt-4">
            Our strategic partners are Tapio Technologies, Andritz and Vits
            Technology. Scienta has its headquarters in Jorvas, Finland close to
            Helsinki and development centers in Jyväskylä and Manchester, UK
            (Scienta Systems Ltd). Scienta Oy is a subsidiary of{" "}
            <a
              href="https://www.tasowheel.fi/tasowheel-systems/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-900 font-medium"
            >
              TasoWheel Oy
            </a>
            .
          </p>
          <a
            href="/other/Scienta_general_brochure_2020.pdf"
            className="inline-block mt-8 px-4 py-2 font-medium rounded-full shadow-md border hover:bg-gray-200"
            target="_blank"
          >
            Product brochure ->
          </a>
          <a
            href="/other/Scienta_Remote_support.pdf"
            className="inline-block mt-8 px-4 py-2 font-medium rounded-full shadow-md border hover:bg-gray-200 ml-4"
            target="_blank"
          >
            Remote support brochure ->
          </a>
        </div>
      </section>
      <section className="px-8 py-24 border-t bg-gray-200">
        <div className="sm:w-2/3 mx-auto">
          <p className="title text-3xl">Measuring systems</p>
          <p className="text-lg mt-4">
            The sensors need a platform to be able to measure online i.e. we put
            our sensors in harm’s way directly into the process. During the
            years we have developed a multitude of scanner platforms giving us a
            robust experience base to benefit our customers.
          </p>
          <p className="text-lg mt-4">
            We combine the scanner platforms with standard PLC technology to
            enable the customer to both understand what he is buying but also
            put him in a position to trouble shoot the system himself. It
            enables us to customize the system to fit the need accurately and at
            a modest cost.
          </p>
          <div className="sm:flex sm:flex-wrap">
            <div className="sm:w-1/2 mt-12">
              <p className="title text-xl">For Pulp & Paper</p>
              <p className="text-lg mt-4">
                We combine the newly developed large frame scanner featuring
                liquid cooled sensor platform and an ultra-stable guiding system
                with the state-of-the-art Microwave sensor measuring both Basis
                Weight and Moisture in a very wide range. We complement the
                basic measuring values with a new non-contact Caliper sensor,
                Coating sensor (NIR) and a Brightness measurement for the Pulp
                dryers.
              </p>
              <Link to="/pulp" className="button mt-4 inline-block">
                Read more
              </Link>
            </div>
            <div className="sm:w-1/2 pl-0 sm:pl-12 mt-12">
              <p className="title text-xl">For Resin Treaters</p>
              <p className="text-lg mt-4">
                Scienta offers the widest range of scanner systems for an
                Impregnation line available today. We also control the resin
                amount automatically in a correct fashion bringing raw material
                cost reductions to the table. The ability to integrate the
                measuring system into the line makes our offer very attractive
                be a Siemens controlled line or a Allen-Bradley type setup.
              </p>
              <Link to="/resin" className="button mt-4 inline-block">
                Read more
              </Link>
            </div>
            <div className="sm:w-1/2 mt-12">
              <p className="title text-xl">For Nonwoven Products</p>
              <p className="text-lg mt-4">
                Here we can offer a range of single sided sensors developed for
                the paper machine Felt production. The Microwave contact Basis
                Sensor is complemented by the positive air flow Air permeability
                sensor. All this coupled with comprehensive reporting and
                analytics, which include SQL-based .
              </p>
              <Link to="/nonwoven" className="button mt-4 inline-block">
                Read more
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="px-8 py-24 border-t">
        <div className="sm:w-2/3 mx-auto">
          <p className="title text-3xl">Products for OEM customers</p>
          <p className="text-lg mt-4">
            We offer a few of our models for OEM customers.
          </p>
          <div className="sm:flex">
            <div className="sm:w-1/2 mt-12">
              <p className="title text-xl">Scanners</p>
              <p className="text-lg mt-4">
                We offer a few of our scanner models for OEM customers. The
                sturdy single sided scanner can carry a heavy payload and still
                position the sensor accurately on the line. Being it in the X, Y
                or Z direction. High speed PLC control makes integration easy as
                we OPC or direct Ethernet/Profinet links to control everything.
              </p>
              <Link to="/scanners" className="button mt-4 inline-block">
                Read more
              </Link>
            </div>
            <div className="sm:w-1/2 ml-0 sm:ml-12 mt-12">
              <p className="title text-xl">Sensors</p>
              <p className="text-lg mt-4">
                Especially the newly developed, high-performance Microwave
                sensor model 7250S is an item we offer for the OEM market as
                well. It is perfect for rebuilds as a high performance moisture
                sensor.
              </p>
              <Link to="/sensors" className="button mt-4 inline-block">
                Read more
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="px-8 py-24 border-t bg-gray-200">
        <div className="sm:w-2/3 mx-auto text-center">
          <p className="title text-3xl">Microwave sensor technology</p>
          <p className="text-lg mt-4">
            The microwave measurement technology has developed during recent
            years in component technology (mobile phones) but also in raw
            processor power needed to calculate in real time the sophisticated
            algorithms needed to model the wave forms in a resonance cavity.
          </p>
          <p className="text-lg mt-4">
            The sensor is compact in size but includes three different
            processors to achieve a real-time output signal. The sensor comes in
            a double sided ‘split cavity’ form and as a contact sensor for
            heavier materials. We have included interfaces ranging from voltage,
            mA loop, Modbus RTU, Profibus and Modbus TCP/IP through WiFi - all
            as standard. The sensor is easy to use and the temperature
            controllers for the cavities are also built in. You only need to
            connect a 24VDC power feed, and read the measurement signals.
          </p>
        </div>
      </section>
    </>
  );
};

export default Home;
