import React from "react";
import Impregnation from "../assets/image/Impregnation.jpg";
import TechTable from "../components/TechTable";
import FeatureCard from "../components/FeatureCard";
import { ReactComponent as Check } from "feather-icons/dist/icons/check.svg";

const DETAILS = [
  {
    key: "Product width",
    value: "up to 5000 mm",
  },
  {
    key: "Head scanning speed",
    value: "100 to 400 mm",
  },
  {
    key: "Max sensors in platform",
    value: "3-5",
  },
  {
    key: "Liquid cooling of sensor platform",
    value: "Only when required",
  },
  {
    key: "Over pressurized scanner frame with fresh air intakes 200 mm",
    value: <Check className="icon h-6" />,
  },
  {
    key: "Head tilt possibility",
    value: "+/- 35 degrees",
  },
  {
    key: "Field bus in sensor platform",
    value: <Check className="icon h-6" />,
  },
  {
    key: "Built in PLC in scanner frame",
    value: <Check className="icon h-6" />,
  },
  {
    key: "Very simple integration into line",
    value: <Check className="icon h-6" />,
  },
  {
    key: "Direct Profinet/ Ethernet connection to line control PLC",
    value: <Check className="icon h-6" />,
  },
  {
    key: "Special frame for threading chain navigation",
    value: <Check className="icon h-6" />,
  },
];

const Pulp = () => {
  return (
    <>
      <header
        className="relative bg-blue-900 overflow-hidden"
        style={{
          height: "40rem",
          backgroundImage: `url(${Impregnation})`,
          backgroundSize: "cover",
          bacgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
        }}
      >
        <div
          className="absolute inset-0 flex items-center justify-center text-white px-8"
          style={{
            background: "rgba(14,57,103,0.7)",
          }}
        >
          <div className="text-center sm:w-2/3">
            <p className="title text-2xl">Measuring systems for</p>
            <p className="title text-5xl">Resin treaters</p>
          </div>
        </div>
      </header>

      <section className="px-8 py-24">
        <div className="sm:w-2/3 mx-auto text-center">
          <p className="title text-3xl">Overview</p>
          <p className="text-lg mt-8">
            Scienta has been supplying online measurement and resin control
            systems for the Resin Impregnation industry since 1974. Our tight
            cooperation with VITS ensures that we have a comprehensive range of
            sensors to offer. Our systems can withstand aluminum oxide, resin
            dust and the challenging environment of an Impregnation line. Our
            sensor are optimized to save raw material and ensure an even product
            quality.
          </p>
        </div>
      </section>
      <section className="px-8 py-24 border-t bg-gray-200">
        <div className="text-center">
          <p className="title text-3xl mb-4">Features</p>
          <div className="sm:flex sm:flex-wrap sm:-mx-4">
            <div className="sm:w-1/4 sm:p-4 mt-12 sm:mt-0">
              <FeatureCard
                title="Online sensors"
                items={[
                  {
                    name: "Total weight",
                    link: "/sensors/Scienta_BW_Basis_Weight.pdf",
                  },
                  {
                    name: "Resin weight",
                    link: "/sensors/Scienta_BW_Basis_Weight.pdf",
                  },
                  {
                    name: "Volatiles",
                    link: "/sensors/Scienta_MW_Moisture.pdf",
                  },
                  "Sheet temperature",
                  {
                    name: "Ash content (corund)",
                    link: "/sensors/Scienta_ASH_7213X.pdf",
                  },
                ]}
              />
            </div>
            <div className="sm:w-1/4 sm:p-4 mt-12 sm:mt-0">
              <FeatureCard
                title="Scanner frames"
                items={[
                  {
                    name: "Large frame double sided scanner",
                    link: "/scanners/Scienta_1010_Scanner.pdf",
                  },
                  {
                    name: "Medium size double sided scanner",
                    link: "/scanners/Scienta_9110_Scanner.pdf",
                  },
                  {
                    name: "Single sided scanner",
                    link: "/scanners/Scienta_9145_Scanner.pdf",
                  },
                  {
                    name: "Fixed point scanner",
                    link: "/scanners/Scienta_9144_Scanner.pdf",
                  },
                  "Mobile units",
                  {
                    name: "Explosion-proof area scanners",
                    link: "/scanners/Scienta_1010_Scanner.pdf",
                  },
                ]}
              />
            </div>
            <div className="sm:w-1/4 sm:p-4 mt-12 sm:mt-0">
              <FeatureCard
                title="User interface/IO"
                items={[
                  "Siemens WinCC Flexible",
                  "Allen-Bradley RSView Studio",
                  "OPC Server",
                  "SQL Database",
                  "Excel format data gathering",
                ]}
              />
            </div>
            <div className="sm:w-1/4 sm:p-4 mt-12 sm:mt-0">
              <FeatureCard
                title="Automatic controls"
                items={["Resin amount", "Resin profile"]}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="px-8 py-24 border-t">
        <div className="text-center">
          <p className="title text-3xl mb-4">Technical details</p>
          <div className="sm:w-2/3 mx-auto">
            <TechTable items={DETAILS} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Pulp;
