import React from "react";

const News = ({ data, newsId }) => {
  const { news } = data;
  const n = news.find(v => String(v.id) === newsId);
  if (!n) {
    return null;
  }
  return (
    <>
      <header className="relative bg-blue-900" style={{ minHeight: "40rem" }}>
        <div className="absolute inset-0 flex items-center justify-center text-white px-8">
          <div className="text-center sm:w-2/3">
            <p className="title text-2xl">{n.title}</p>
            <p className="text-lg mt-4">{n.content}</p>
            <p className="mt-4 text-gray-500 text-sm">
              Posted at {n.created_at}
            </p>
          </div>
        </div>
      </header>
    </>
  );
};

export default News;
