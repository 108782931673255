import React from "react";
import ScannerImage from "../assets/image/Scanner_nonwhite.jpg";
import LargeFrame from "../assets/image/largeframe.jpg";
import { Link } from "@reach/router";

const Scanners = () => {
  return (
    <>
      <header
        className="relative bg-blue-900 overflow-hidden"
        style={{
          height: "40rem",
          backgroundImage: `url(${ScannerImage})`,
          backgroundSize: "cover",
          bacgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
        }}
      >
        <div
          className="absolute inset-0 flex items-center justify-center text-white px-8"
          style={{
            background: "rgba(14,57,103,0.7)",
          }}
        >
          <div className="text-center sm:w-2/3">
            <p className="title text-2xl">Products for OEM customers</p>
            <p className="title text-5xl">Scanner frames</p>
          </div>
        </div>
      </header>

      <section className="px-8 py-24">
        <div className="sm:w-2/3 mx-auto text-center">
          <p className="title text-3xl">Overview</p>
          <p className="text-lg mt-8">
            We offer a few of our scanner models for OEM customers. The sturdy
            single sided scanner can carry a heavy payload and still position
            the sensor accurately on the line. Being it in the X, Y or Z
            direction. High speed PLC control makes integration easy as we OPC
            or direct Ethernet/Profinet links to control everything.
          </p>
        </div>
      </section>
      <section className="px-8 py-24 border-t">
        <div className="sm:w-2/3 mx-auto text-center">
          <p className="title text-3xl">Products</p>
          <table className="text-left w-full mt-8">
            <thead>
              <tr>
                <th className="py-2 text-gray-700">Frame type</th>
                <th className="py-2 text-gray-700">Scanning width</th>
                <th className="py-2 text-gray-700">Features</th>
                <th className="py-2 text-gray-700">Brochure</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-t">
                <td className="py-2">Large frame scanner model 1010</td>
                <td className="py-2">Up to 10 000 mm</td>
                <td className="py-2">
                  Liquid cooled sensor platform, also for harsh environments
                </td>
                <td className="py-2">
                  <a
                    href="/scanners/Scienta_1010_Scanner.pdf"
                    className="text-blue-700 hover:underline"
                    target="_blank"
                  >
                    Download
                  </a>
                </td>
              </tr>
              <tr className="border-t">
                <td className="py-2">Medium size scanner model 9110 </td>
                <td className="py-2">Up to 5 000 mm</td>
                <td className="py-2">
                  Withstands dust and abrasive particles very well
                </td>
                <td className="py-2">
                  <a
                    href="/scanners/Scienta_9110_Scanner.pdf"
                    className="text-blue-700 hover:underline"
                    target="_blank"
                  >
                    Download
                  </a>
                </td>
              </tr>
              <tr className="border-t">
                <td className="py-2">Single sided scanner model 9145</td>
                <td className="py-2">Up to 15 000 mm</td>
                <td className="py-2">
                  Very durable scanner with large payload capacity; simple
                  interface
                </td>
                <td className="py-2">
                  <a
                    href="/scanners/Scienta_9145_Scanner.pdf"
                    className="text-blue-700 hover:underline"
                    target="_blank"
                  >
                    Download
                  </a>
                </td>
              </tr>
              <tr className="border-t">
                <td className="py-2">Fixed point scanner model 9144</td>
                <td className="py-2">400 mm from sheet edge </td>
                <td className="py-2">
                  All stainless steel construction for narrow space
                  installations
                </td>
                <td className="py-2">
                  <a
                    href="/scanners/Scienta_9144_Scanner.pdf"
                    className="text-blue-700 hover:underline"
                    target="_blank"
                  >
                    Download
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section className="px-8 py-24 border-t">
        <div className="text-center">
          <img
            src={LargeFrame}
            alt="Large frame scanner"
            className="w-full"
          ></img>
          <p className="mt-4 text-xs">The Large Frame scanner model 1010.</p>
        </div>
      </section>
      <section className="px-8 py-24 border-t">
        <div className="sm:w-2/3 mx-auto">
          <p className="title text-3xl">Service</p>
          <div className="sm:flex">
            <div className="sm:w-1/2 mt-8">
              <p className="title text-xl">After sales</p>
              <p className="text-lg mt-4">
                Everyone in our team is dedicated to After Sales service. We are
                easy to contact and get hold of. We have service personnel in
                different parts of the world.
              </p>
              <Link className="button mt-4 inline-block" to="/contact">
                View contacts
              </Link>
            </div>
            <div className="sm:w-1/2 ml-0 sm:ml-12 mt-8">
              <p className="title text-xl">Sensors</p>
              <p className="text-lg mt-4">
                A comprehensive Spare part list is accompanied by all systems we
                deliver. Because of the use of standard components our Spare
                parts list is short and the few items are sensibly priced. As
                our sensors are usually built around a single electronic card we
                can keep the Spare parts list very short. We have most parts ex
                stock and we always deliver by courier freight.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Scanners;
