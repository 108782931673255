import React, { useEffect, useState } from "react";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Pulp from "./pages/Pulp";
import Resin from "./pages/Resin";
import Nonwoven from "./pages/Nonwoven";
import Scanners from "./pages/Scanners";
import Sensors from "./pages/Sensors";
import { Router } from "@reach/router";
import axios from "axios";
import { ScrollToTop } from "./components/ScrollToTop";
import News from "./pages/News";

const ENDPOINTS = ["news", "people", "sales", "representatives"];
const useEndpoints = () => {
  const [data, setData] = useState({
    news: [],
    people: [],
    sales: [],
    representatives: []
  });
  useEffect(() => {
    ENDPOINTS.forEach(endpoint => {
      axios
        .get(
          "https://sheets.googleapis.com/v4/spreadsheets/1_5HAfLlbn067xtB5NwKvAn6OUYCxKcxq6Zcf8BwsWbY/values/" +
            endpoint,
          {
            params: {
              key: "AIzaSyA47rGSU5T73sr6n-SOthwJEhwNxJqIM0s"
            }
          }
        )
        .then(response => {
          setData(p => ({
            ...p,
            [endpoint]: response.data.values
              .filter((row, i) => i !== 0 && row && row.length >= 3)
              .map((row, i) => {
                if (endpoint === "news") {
                  return {
                    id: i,
                    title: row[0],
                    content: row[1],
                    created_at: row[2]
                  };
                } else {
                  return {
                    name: row[0],
                    title: row[1],
                    email: row[2],
                    phone: row[3]
                  };
                }
              })
          }));
        })
        .catch(error => {
          console.log(error);
        });
    });
  }, [setData]);

  return data;
};

function App() {
  const data = useEndpoints();
  return (
    <div className="h-min-screen bg-gray-100">
      <div className="container mx-auto shadow-lg border-l border-r relative bg-white">
        <Navbar />
        <Router primary={false}>
          <ScrollToTop path="/">
            <Home path="/" data={data} />
            <Pulp path="/pulp" data={data} />
            <Resin path="/resin" data={data} />
            <Nonwoven path="/nonwoven" data={data} />
            <Scanners path="/scanners" data={data} />
            <Sensors path="/sensors" data={data} />
            <About path="/about" data={data} />
            <Contact path="/contact" data={data} />
            <News path="/news/:newsId" data={data} />
          </ScrollToTop>
        </Router>
        <Footer data={data} />
      </div>
    </div>
  );
}

export default App;
