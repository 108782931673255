import React from "react";
import PulpPaper from "../assets/image/PulpPaper.JPG";
import { ReactComponent as Check } from "feather-icons/dist/icons/check.svg";
import TechTable from "../components/TechTable";
import FeatureCard from "../components/FeatureCard";

const DETAILS = [
  {
    key: "Sheet width double sided scanner",
    value: "up to 10 m",
  },
  {
    key: "Web width double sided scanner",
    value: "up to 15 m",
  },
  {
    key: "Head scanning speed",
    value: "100 to 400 mm",
  },
  {
    key: "Max sensors in platform",
    value: "3-5",
  },
  {
    key: "Liquid cooling of sensor platform",
    value: <Check className="icon h-6" />,
  },
  {
    key: "Over pressurized scanner frame with fresh air intakes 200 mm",
    value: <Check className="icon h-6" />,
  },
  {
    key: "Head tilt possibility",
    value: "+/- 35 degrees",
  },
  {
    key: "Field bus in sensor platform",
    value: <Check className="icon h-6" />,
  },
  {
    key: "Built in PLC in scanner frame",
    value: <Check className="icon h-6" />,
  },
  {
    key: "Very simple integration into line",
    value: <Check className="icon h-6" />,
  },
  {
    key: "Direct OPC connection to scanner",
    value: <Check className="icon h-6" />,
  },
];
const Pulp = () => {
  return (
    <>
      <header
        className="relative bg-blue-900 overflow-hidden"
        style={{
          height: "40rem",
          backgroundImage: `url(${PulpPaper})`,
          backgroundSize: "cover",
          bacgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
        }}
      >
        <div
          className="absolute inset-0 flex items-center justify-center text-white px-8"
          style={{
            background: "rgba(14,57,103,0.7)",
          }}
        >
          <div className="text-center sm:w-2/3">
            <p className="title text-2xl">Measuring systems for</p>
            <p className="title text-5xl">Pulp & Paper</p>
          </div>
        </div>
      </header>

      <section className="px-8 py-24">
        <div className="sm:w-2/3 mx-auto text-center">
          <p className="title text-3xl">Overview</p>
          <p className="text-lg mt-8">
            Scienta specializes in innovative, modular QCS systems that are
            tailored to the customer’s needs. Our strength lies in online
            scanner systems for specialty paper and pulp dryer lines, where the
            customer would like standard PLC components and open software
            solutions. We have been producing reliable sensors with the latest
            Field Bus connections right in the sensor electronics since the
            1970’s.
          </p>
        </div>
      </section>
      <section className="px-8 py-24 border-t bg-gray-200">
        <div className="text-center">
          <p className="title text-3xl mb-4">Features</p>
          <div className="sm:flex sm:flex-wrap sm:-mx-4">
            <div className="sm:w-1/4 sm:p-4 mt-12 sm:mt-0">
              <FeatureCard
                title="Online sensors"
                items={[
                  {
                    name: "Basis weight",
                    link: "/sensors/Scienta_BW_Basis_Weight.pdf",
                  },
                  {
                    name: "Moisture",
                    link: "/sensors/Scienta_MW_Moisture.pdf",
                  },
                  {
                    name: "Ash content",
                    link: "/sensors/Scienta_ASH_7213X.pdf",
                  },
                  {
                    name: "Caliper",
                    link: "/sensors/Scienta_Thickness.pdf",
                  },
                  {
                    name: "Brightness",
                    link: "/sensors/Scienta_Brightness.pdf",
                  },
                  {
                    name: "Coat weight",
                    link: "/sensors/Scienta_Coating_NIR.pdf",
                  },
                  "Sheet temperature",
                  {
                    name: "Air permeability",
                    link: "/sensors/Scienta_Porosity.pdf",
                  },
                ]}
              />
            </div>
            <div className="sm:w-1/4 sm:p-4 mt-12 sm:mt-0">
              <FeatureCard
                title="Scanner frames"
                items={[
                  {
                    name: "Large frame double sided scanner",
                    link: "/scanners/Scienta_1010_Scanner.pdf",
                  },
                  {
                    name: "Medium size double sided scanner",
                    link: "/scanners/Scienta_9110_Scanner.pdf",
                  },
                  {
                    name: "Single sided scanner",
                    link: "/scanners/Scienta_9145_Scanner.pdf",
                  },
                  {
                    name: "Fixed point scanner",
                    link: "/scanners/Scienta_9144_Scanner.pdf",
                  },
                  "Mobile units",
                ]}
              />
            </div>
            <div className="sm:w-1/4 sm:p-4 mt-12 sm:mt-0">
              <FeatureCard
                title="User interface/IO"
                items={[
                  "Siemens WinCC Client/Server",
                  "Siemens WinCC Flexible",
                  "Allen-Bradley RSView Studio",
                  "OPC Server",
                  "SQL Database",
                  "Excel format data gathering",
                ]}
              />
            </div>
            <div className="sm:w-1/4 sm:p-4 mt-12 sm:mt-0">
              <FeatureCard
                title="Automatic controls"
                items={[
                  {
                    name: "Basis Weight MD and CD",
                    link: "/other/Scienta_MD_CD.pdf",
                  },
                  {
                    name: "Moisture control MD and CD",
                    link: "/other/Scienta_Rewet.pdf",
                  },
                  {
                    name: "Dilution and Lip actuators CD",
                    link: "/other/Scienta_CDactuators.pdf",
                  },
                  {
                    name: "Moisturizer CD",
                    link: "/other/Scienta_Rewet.pdf",
                  },
                  "Steam box and Devronizer CD",
                ]}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="px-8 py-24 border-t">
        <div className="text-center">
          <p className="title text-3xl mb-4">Technical details</p>
          <div className="sm:w-2/3 mx-auto">
            <TechTable items={DETAILS}></TechTable>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pulp;
