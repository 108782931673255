import React from "react";
import { ReactComponent as Mail } from "feather-icons/dist/icons/mail.svg";
import { ReactComponent as Phone } from "feather-icons/dist/icons/phone.svg";

export const Person = ({ person }) => {
  return (
    <div className="p-4 rounded border hover:shadow-md text-left">
      <p className="font-semibold">{person.name}</p>
      <p className="text-sm text-gray-700">{person.title}</p>
      <a
        href={"tel:" + person.phone}
        className="text-sm mt-4 flex items-center hover:opacity-75"
      >
        <Phone className="icon" />
        <span className="ml-2">{person.phone}</span>
      </a>
      <a
        href={"mailto:" + person.email}
        className="text-sm mt-2 flex items-center hover:opacity-75"
      >
        <Mail className="icon" />
        <span className="ml-2">{person.email}</span>
      </a>
    </div>
  );
};

const About = ({ data }) => {
  const { people } = data;
  return (
    <>
      <header className="relative bg-blue-900" style={{ height: "40rem" }}>
        <div className="absolute inset-0 flex items-center justify-center text-white px-8">
          <div className="text-center sm:w-2/3">
            <p className="title text-5xl">About</p>
            <p className="text-xl mt-4">
              Scienta designs and manufactures high end online measurement and
              control systems.
            </p>
          </div>
        </div>
      </header>
      <section className="px-8 py-24">
        <div className="sm:w-2/3 mx-auto text-center">
          <p className="title text-3xl">Company</p>
          <p className="text-lg mt-8">
            We can safely argue that Scienta has been involved in hundreds of
            different ways of measuring and controlling online processes. We
            have steadily developed our range of online sensors targeting a
            specific need in the market – not copying others. The three layer
            model (sensors / scanners / control) has been a guideline over a
            number of years - all connected with each other using industry
            standard bus technologies. This way we can offer our clients a
            modular approach to building measuring systems. Our customers can
            freely chose the sensor, the scanner type, and the control system to
            suit their needs.
          </p>
          <p className="text-lg mt-4">
            We service the Pulp & Paper, Impregnated Paper, Laminated products
            and the Nonwoven materials industries. Our partners are Tapio
            Technologies, Andritz and Vits Technology. Scienta is a subsidiary
            of TasoWheel Oy.
          </p>
          <p className="text-lg mt-4">
            Scienta is a privately owned Finnish company, founded in 1954, with
            clients in 25+ countries.
          </p>
        </div>
      </section>
      <section className="px-8 py-24 border-t bg-gray-200">
        <div className="sm:w-2/3 mx-auto text-center">
          <p className="title text-3xl">Technology</p>
          <p className="text-lg mt-4">
            We are specialized in Microwave technology sensors and have
            developed a range of comprehensive, high performance sensors based
            on this technology. Scienta is specialized in custom online
            measurements where we provide both Single sensors and complete QCS
            systems using standard PLC control systems. We can provide Siemens
            or Allen -Bradley based systems to make integration into the target
            machine as easy as possible. The product range includes advanced
            machine- and cross-direction controls.
          </p>
        </div>
      </section>
      <section className="px-8 py-24 border-t">
        <div className="sm:w-2/3 mx-auto text-center">
          <p className="title text-3xl mb-4">People</p>
          <div className="sm:flex sm:flex-wrap sm:-mx-4">
            {people.map((person, i) => {
              return (
                <div key={i} className="sm:w-1/2 p-4">
                  <Person person={person} />
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="px-8 py-24 border-t bg-gray-200">
        <div className="sm:w-2/3 mx-auto text-center">
          <p className="title text-3xl">Location</p>
          <p className="text-lg mt-4">
            Scienta has its headquarters in Espoo, Finland and development
            centers in Jyväskylä and Manchester, UK.
          </p>
        </div>
      </section>
      <section className="border-t">
        <iframe
          title="Map"
          height="560"
          frameBorder="0"
          style={{
            border: 0,
            width: "100%",
          }}
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA47rGSU5T73sr6n-SOthwJEhwNxJqIM0s&q=Scienta+Oy"
        />
      </section>
    </>
  );
};

export default About;
