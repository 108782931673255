import React from "react";
import { Link } from "@reach/router";

const Footer = ({ data }) => {
  const { news } = data;
  return (
    <footer className="px-8 py-24 border-t bg-blue-900 text-white">
      <div className="sm:w-2/3 mx-auto sm:flex">
        <div className="sm:w-1/4">
          <p className="logo text-xl">Scienta</p>
          <ul className="text-gray-200 text-xs border-l pl-4">
            <li className="mt-2">+358 207 757 400</li>
            <li className="mt-2">info@scienta.fi</li>
            <li className="mt-2">
              <p className="font-semibold">Visiting address:</p>
              <p>Kalkkipellontie 6</p>
              <p>FIN-02650 Espoo</p>
            </li>
            <li className="mt-2">
              <p className="font-semibold">Invoice address:</p>
              <p>Hepolamminkatu 27a</p>
              <p>FIN-33720 Tampere</p>
            </li>
            <li className="mt-2">VAT nr FI 30221576</li>
          </ul>
          <p className="mt-4 text-xs text-gray-500">© Scienta Oy 1954-2023</p>
        </div>
        <div className="sm:w-2/4 mt-12 sm:mt-0 sm:flex">
          <div className="sm:w-1/2">
            <p className="title mt-2">Measuring systems</p>
            <ul className="text-gray-200 text-xs">
              <li className="mt-2">
                <Link to="/pulp" className="hover:opacity-75">
                  Pulp & paper
                </Link>
              </li>
              <li className="mt-2">
                <Link to="/resin" className="hover:opacity-75">
                  Resin treaters
                </Link>
              </li>
              <li className="mt-2">
                <Link to="/nonwoven" className="hover:opacity-75">
                  Nonwoven products
                </Link>
              </li>
            </ul>
            <p className="title mt-12">Navigation</p>
            <ul className="text-gray-200 text-xs">
              <li className="mt-2">
                <Link to="/about" className="hover:opacity-75">
                  About
                </Link>
              </li>
              <li className="mt-2">
                <Link to="/contact" className="hover:opacity-75">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="sm:w-1/2 mt-12 sm:mt-0 ml-0 sm:ml-12">
            <p className="title mt-2">OEM Products</p>
            <ul className="text-gray-200 text-xs">
              <li className="mt-2">
                <Link to="/scanners" className="hover:opacity-75">
                  Scanners
                </Link>
              </li>
              <li className="mt-2">
                <Link to="/sensors" className="hover:opacity-75">
                  Sensors
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="sm:w-1/4 mt-12 sm:mt-0">
          <p className="title mt-2">News</p>
          <ul className="text-gray-200 text-xs">
            {[...news]
              .reverse()
              .slice(0, 5)
              .map((row, i) => {
                return (
                  <li key={i} className="mt-2">
                    <Link
                      to={"/news/" + row.id}
                      className="hover:opacity-75 block"
                    >
                      <p className="truncate" title={row.title}>
                        {row.title}
                      </p>
                      <p className="text-gray-500">{row.created_at}</p>
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
