import React from "react";
import { Menu, MenuButton, MenuLink, MenuList } from "@reach/menu-button";
import { Link } from "@reach/router";
import { ReactComponent as ChevronDown } from "feather-icons/dist/icons/chevron-down.svg";

const ProductMenu = () => {
  return (
    <Menu>
      <MenuButton className="title text-lg hover:opacity-75 flex items-center focus:outline-none focus:opacity-50">
        <span className="block sm:hidden">Menu</span>
        <span className="hidden sm:block">Products</span>
        <ChevronDown className="icon"></ChevronDown>
      </MenuButton>
      <MenuList className="mt-2 w-64">
        <>
          <div className="px-4 pt-4 pb-2">
            <p className="title text-gray-700">Measuring systems</p>
          </div>
        </>
        <MenuLink as={Link} to="/pulp">
          Pulp & Paper
        </MenuLink>
        <MenuLink as={Link} to="/resin">
          Resin treates
        </MenuLink>
        <MenuLink as={Link} to="/nonwoven">
          Nonwoven
        </MenuLink>
        <>
          <div className="px-4 pt-4 pb-2 border-t">
            <p className="title text-gray-700">OEM products</p>
          </div>
        </>
        <MenuLink as={Link} to="/scanners">
          Scanners
        </MenuLink>
        <MenuLink as={Link} to="/sensors">
          Sensors
        </MenuLink>
        <>
          <div className="px-4 pt-4 pb-2 border-t sm:hidden">
            <p className="title text-gray-700">Navigation</p>
          </div>
        </>
        <MenuLink as={Link} to="/about" className="sm:hidden">
          About
        </MenuLink>
        <MenuLink as={Link} to="/contact" className="sm:hidden">
          Contact
        </MenuLink>
      </MenuList>
    </Menu>
  );
};

const Navbar = () => {
  return (
    <nav className="absolute top-0 left-0 right-0 h-20 flex items-center px-8 text-white z-10">
      <Link to="/" className="logo text-3xl">
        Scienta
      </Link>
      <div className="flex-1"></div>
      <ProductMenu />
      <Link
        to="/about"
        className="title text-lg ml-8 hover:opacity-75 hidden sm:block"
      >
        About
      </Link>
      <Link
        to="/contact"
        className="title text-lg ml-8 hover:opacity-75 hidden sm:block"
      >
        Contact
      </Link>
    </nav>
  );
};

export default Navbar;
