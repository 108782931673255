import React from "react";
import SensorTopImage from "../assets/image/Sensors_nonwhite.jpg";
import SensorImage from "../assets/image/Sensor.png";
import { Link } from "@reach/router";

const Scanners = () => {
  return (
    <>
      <header
        className="relative bg-blue-900 overflow-hidden"
        style={{
          height: "40rem",
          backgroundImage: `url(${SensorTopImage})`,
          backgroundSize: "cover",
          bacgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
        }}
      >
        <div
          className="absolute inset-0 flex items-center justify-center text-white px-8"
          style={{
            background: "rgba(14,57,103,0.7)",
          }}
        >
          <div className="text-center sm:w-2/3">
            <p className="title text-2xl">Products for OEM customers</p>
            <p className="title text-5xl">Sensors</p>
          </div>
        </div>
      </header>

      <section className="px-8 py-24">
        <div className="sm:w-2/3 mx-auto text-center">
          <p className="title text-3xl">Overview</p>
          <p className="text-lg mt-8">
            Especially the newly developed, high-performance Microwave sensor
            model 7250S is an item we offer for the OEM market as well. It is
            perfect for rebuilds as a high performance moisture sensor.
          </p>
        </div>
      </section>
      <section className="px-8 py-24 border-t">
        <div className="sm:w-2/3 mx-auto text-center">
          <p className="title text-3xl">Products</p>
          <table className="text-left w-full mt-8">
            <thead>
              <tr>
                <th className="py-2 text-gray-700">Measuring technology</th>
                <th className="py-2 text-gray-700">Measuring property </th>
                <th className="py-2 text-gray-700">Sensing range</th>
                <th className="py-2 text-gray-700">Brochure</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-t">
                <td className="py-2">High performance moisture sensor</td>
                <td className="py-2">Moisture</td>
                <td className="py-2">
                  Weight 50..5000 g/m2
                  <br />
                  Moisture 2..90 %
                </td>
                <td className="py-2">
                  <a
                    href="/sensors/Scienta_MW_Moisture.pdf"
                    className="text-blue-700 hover:underline"
                    target="_blank"
                  >
                    Download
                  </a>
                </td>
              </tr>
              <tr className="border-t">
                <td className="py-2">Beta radiation using Ion Chamber</td>
                <td className="py-2">Basis Weight</td>
                <td className="py-2">Weight 20..900 g/m2</td>
                <td className="py-2">
                  <a
                    href="/sensors/Scienta_BW_Basis_Weight.pdf"
                    className="text-blue-700 hover:underline"
                    target="_blank"
                  >
                    Download
                  </a>
                </td>
              </tr>
              <tr className="border-t">
                <td className="py-2">
                  NIR based 2 or 4 channel fixed point sensor
                </td>
                <td className="py-2">Moisture</td>
                <td className="py-2">Moisture 0..20 %</td>
                <td className="py-2">
                  <a
                    href="/sensors/Scienta_NIR_Moisture.pdf"
                    className="text-blue-700 hover:underline"
                    target="_blank"
                  >
                    Download
                  </a>
                </td>
              </tr>
              <tr className="border-t">
                <td className="py-2">
                  Visible light according to TAPPI standard
                </td>
                <td className="py-2">Brightness</td>
                <td className="py-2">80..100 %</td>
                <td className="py-2">
                  <a
                    href="/sensors/Scienta_Brightness.pdf"
                    className="text-blue-700 hover:underline"
                    target="_blank"
                  >
                    Download
                  </a>
                </td>
              </tr>
              <tr className="border-t">
                <td className="py-2">
                  Positive air pressure sensor suitable for scanning porous
                  materials
                </td>
                <td className="py-2">Air permeability</td>
                <td className="py-2">Especially for porous materials</td>
                <td className="py-2">
                  <a
                    href="/sensors/Scienta_Porosity.pdf"
                    className="text-blue-700 hover:underline"
                    target="_blank"
                  >
                    Download
                  </a>
                </td>
              </tr>
              <tr className="border-t">
                <td className="py-2">
                  Magnetic reluctance based, air-cushion type sensor
                </td>
                <td className="py-2">Caliper</td>
                <td className="py-2">40..1000 µm</td>
                <td className="py-2">
                  <a
                    href="/sensors/Scienta_Thickness.pdf"
                    className="text-blue-700 hover:underline"
                    target="_blank"
                  >
                    Download
                  </a>
                </td>
              </tr>
              <tr className="border-t">
                <td className="py-2">
                  NIR based single sided coat weight measurement
                </td>
                <td className="py-2">Coat weight</td>
                <td className="py-2">1..50 g/m2</td>
                <td className="py-2">
                  <a
                    href="/sensors/Scienta_Coating_NIR.pdf"
                    className="text-blue-700 hover:underline"
                    target="_blank"
                  >
                    Download
                  </a>
                </td>
              </tr>
              <tr className="border-t">
                <td className="py-2">
                  X-ray based technology using scintillation tube receiver
                </td>
                <td className="py-2">Ash content</td>
                <td className="py-2">1..50 g/m2</td>
                <td className="py-2">
                  <a
                    href="/sensors/Scienta_ASH_7213X.pdf"
                    className="text-blue-700 hover:underline"
                    target="_blank"
                  >
                    Download
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section className="px-8 py-24 border-t">
        <div className="text-center">
          <img
            src={SensorImage}
            alt="Microwave sensor head"
            className="sm:w-1/3 mx-auto w-full"
          ></img>
          <p className="mt-4 text-xs">The Microwave sensor head.</p>
        </div>
      </section>
      <section className="px-8 py-24 border-t">
        <div className="sm:w-2/3 mx-auto">
          <p className="title text-3xl">Service</p>
          <div className="sm:flex">
            <div className="sm:w-1/2 mt-8">
              <p className="title text-xl">After sales</p>
              <p className="text-lg mt-4">
                Everyone in our team is dedicated to After Sales service. We are
                easy to contact and get hold of. We have service personnel in
                different parts of the world.
              </p>
              <Link className="button mt-4 inline-block" to="/contact">
                View contacts
              </Link>
            </div>
            <div className="sm:w-1/2 ml-0 sm:ml-12 mt-8">
              <p className="title text-xl">Sensors</p>
              <p className="text-lg mt-4">
                A comprehensive Spare part list is accompanied by all systems we
                deliver. Because of the use of standard components our Spare
                parts list is short and the few items are sensibly priced. As
                our sensors are usually built around a single electronic card we
                can keep the Spare parts list very short. We have most parts ex
                stock and we always deliver by courier freight.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Scanners;
